import { Container } from "react-bootstrap";
import BgImg from "../../asset/img9.jpg"
import styles from "./services.module.css"

function Services() {
    return (
        <div className={`bg-dark text-light ${styles.serviceHeight}`}>
            <div>
                <div className={`${styles.bgImg}`} style={{
                    backgroundImage: `url(${BgImg})`,
                }} >
                    <div className={`${styles.blackBg}`}  >
                        <Container >
                            <div className="pt-md-5 p-2" >
                                <h1 className={`fw-bolder text-light pb-md-5 p-2 text-center ${styles.heading}`}>
                                    Our Services

                                </h1>
                                <h4 className=" text-light  pb-md-5 p-2">
                                    ALFA AUTO is established on the strong basis of enriched experience in various fields like Design, Manufacturing, Materials & Marketing.
                                </h4>
                            </div>
                        </Container>
                    </div>

                </div>
                <Container>
                    <p className="h4 pt-4">Fabrication, Spm, Consultancy</p>
                    <p className="pt-4">Alfa Auto provides consultancy to the engineering companies in various areas like Plant and product upgradation, New Design/ Development and proto type building, setting quality assurance system. </p>
                    <p>To increase productivity & thereof reduced production cost, improve aesthetics by using correct material & appropriate process. Apart from this we offer solutions for the automation, material handling, special purpose machines and Import substitute. We also Design painting & powder coating plants. Economical solutions to the complex coating requirements are our specialty.</p>
                    <div className="ps-md5">
                        <ul className={`${styles.MainAct}`}>
                            <li>Sliding Gates</li>
                            <li>Swing Gates</li>
                            <li>Folding Gates</li>
                            <li>Garage Shutters</li>
                            <li>Boom Barriers</li>
                        </ul>
                    </div>
                </Container>
            </div>
        </div>
    );

}

export default Services;