import { Col, Container, Row } from "react-bootstrap";
import contact from "../../asset/contact.png";
import styles from "./contact.module.css";
import { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "emailjs-com";
function Contact() {
  const inputElement = useRef();
  var show = "d-none";

  const [msgdata, setMsg] = useState({});
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [success, setSucess] = useState(false);
  const handleChangeInputs = (e) => {
    setMsg({ ...msgdata, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailData = {
      subject: "Contact Form - Alfa Automation",
      name: msgdata.name,
      mobile: msgdata.mobile,
      email: msgdata.email,
      message: msgdata.message,
      to: "alfasatara99@gmail.com",
      replyTo: msgdata.email,
    };
    const form = document.createElement("form");
    for (const key in emailData) {
      if (Object.hasOwnProperty.call(emailData, key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = emailData[key];
        form.appendChild(input);
      }
    }
    try {
      setIsEmailSending(true);
      setNotificationMessage("");
      emailjs
        .sendForm(
          "website contact form",
          "template_eaxn0oc",
          form,
          "dVPPPyRhEoB6ft-B_"
        )
        .then((response) => {
          setSucess(true);
          console.log("Email sent successfully", response);
          setNotificationMessage("Email sent successfully");
        })
        .catch((error) => {
          setSucess(false);
          console.error("Email send failed", error);
          setNotificationMessage("Email sending failed");
        });
    } catch (error) {
      console.error("Error while sending email", error);
    } finally {
      setIsEmailSending(false);
    }
  };

  const statusStyle = {
    color: success ? "green" : "red",
    marginTop: "8px",
  };

  return (
    <div className="bg-dark text-light">
      <div>
        <div
          style={{
            backgroundImage: `url(${contact})`,
          }}
        >
          <div className={`${styles.blackBg}`}>
            <Container>
              <div className="py-md-5 p-2">
                <h1
                  className={`fw-bolder text-light py-md-5 p-2 text-center  ${styles.heading}`}
                >
                  Contact Us
                </h1>
              </div>
            </Container>
          </div>
        </div>
        <Container>
          <Row>
            <Col md={6} className="d-flex flex-column">
              <h4 className="py-3 text-center">
                Product Manufactured And Marketed By
              </h4>
              <Row>
                <Col md={6} className="text-center">
                  <div className="d-flex justify-content-center flex-column py-3 ">
                    <span className="fs-4">
                      <i className="fas fa-envelope"></i> Email
                    </span>
                    <a
                      href="mailto:alfasatara99@gmail.com "
                      className="text-light text-decoration-none"
                    >
                      Email : alfasatara99@gmail.com
                    </a>
                  </div>
                </Col>
                <Col md={6} className="text-center">
                  <div className="d-flex justify-content-center flex-column py-3">
                    <span className="fs-4">
                      <i className="fas fa-phone-alt"></i> Phone No
                    </span>
                    <div>
                      Phone No{" "}
                      <a
                        href="tel:9422400178"
                        className="text-decoration-none text-light"
                      >
                        9422400178
                      </a>{" "}
                      /
                      <a
                        href="tel:8379841704"
                        className="text-decoration-none text-light"
                      >
                        {" "}
                        8379841704
                      </a>{" "}
                      <br />
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="text-center md-pt-5 py-3">
                  <div className="d-flex justify-content-center flex-column py-2">
                    <span className="fs-4">
                      <i className="fas fa-map-pin"></i> Address Point
                    </span>
                    <div>
                      N-18, ADDL MIDC, SATARA. - 415004.
                      <br />
                    </div>
                  </div>
                </Col>
                <Col
                  sm={6}
                  className="d-flex justify-content-center align-items-center md-pt-5 py-3"
                >
                  <a
                    href="https://www.google.com/maps/dir/17.699547,73.9712484/ALPHA+AUTO,+N-18,+ADDL,+MIDC,+Satara,+Maharashtra+415004/@17.707642,73.972354,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bc230b05174f5c3:0x76456c74483c7d35!2m2!1d74.0441655!2d17.6620551"
                    target="_blank"
                    className="text-light text-decoration-none fs-5"
                  >
                    <i className="fas fa-route"></i> Get direction{" "}
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <h4 className="text-center py-3">Need Help? Write Us</h4>
              <div>
                <Form
                  className=" pb-3"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <Row className="px-md-5">
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          className="p-2 rounded-pill ps-3"
                          type="text"
                          ref={inputElement}
                          placeholder="Enter Your Name"
                          name="name"
                          onChange={(e) => handleChangeInputs(e)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          className="p-2 rounded-pill ps-3"
                          type="text"
                          maxLength={10}
                          placeholder="Your Contact Number"
                          pattern="[0-9]{10}"
                          name="mobile"
                          onChange={(e) => handleChangeInputs(e)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          className="p-2 rounded-pill ps-3"
                          type="email"
                          placeholder="Your Email"
                          name="email"
                          onChange={(e) => handleChangeInputs(e)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          className={`${styles.textbox} p-2 rounded-5 ps-3`}
                          as="textarea"
                          rows={3}
                          placeholder="Any Massage For Us..."
                          name="message"
                          onChange={(e) => handleChangeInputs(e)}
                          required
                        />
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          variant="primary"
                          type="submit"
                          className="px-5 fs-5"
                        >
                          {isEmailSending ? "Sending" : "Send"}
                          <i className="fas fa-paper-plane"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col md={12} className="text-center">
                    {notificationMessage.length > 0 && (
                      <p style={statusStyle}>{notificationMessage}</p>
                    )}
                  </Col>
                </Row>
                <div className={`px-5 ${show}`}>
                  <div className="fs-5 p-1 rounded-3 text-bg-danger text-center">
                    Please enter the Name correctly
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Contact;
