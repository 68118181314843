import { Col, Container, Row } from "react-bootstrap";
import styles from './product.module.css';
import Card from 'react-bootstrap/Card';
import five from "../../asset/img7.jpg"
import BgImg from '../../asset/gallery2.jpg';
import six from '../../asset/img6.png';
import lift from "../../asset/DSC_5692.JPG";
import lift2 from "../../asset/lift2.jpg"
import lift3 from "../../asset/lift3.jpg"
import lift4 from "../../asset/lift4.jpg"
import ListGroup from 'react-bootstrap/ListGroup';
import Table from "react-bootstrap/Table";

function Products() {
    return (
        <div className="bg-dark ">

            <div className={`${styles.bgImg}`} style={{
                backgroundImage: `url(${BgImg})`
            }} >
                <div className={`${styles.blackBg}`}  >
                    <Container >
                        <div className="pt-md-5 p-2" >
                            <h1 className={`fw-bolder pb-md-5 text-light pb-md-5 p-2 text-center ${styles.heading}`}>
                                Our Products
                            </h1>
                            <h4 className=" text-light  pb-md-5 p-2 ">
                                Today it has become a necessity rather than luxury. For a house/Apartments on busy roads it is very difficult to stop
                                the car on the road and get out of the car to open the door. Auto door opener opens door with remote from a
                                distance of 100 feet. So by the time you reach the gate, it is opened fully which avoids waiting. In case of bungalows
                                it adds up the security as it can't be opened without remote.
                            </h4>
                        </div>
                    </Container>
                </div>

            </div>
            <Container>

                <Row className="pt-5">
                    <Col md={12}>
                        <h4 className="text-light -text-center py-3">We manufacture material handling lifts in various categories to suit specific requirements.
                            Custom built material movement systems - is our speciality.
                            (Various load capacities / space availability / material movement and so on)
                        </h4></Col>
                    <Col md={7} className="m-auto py-5">
                        <p className={`text-center text-light text-lg-start ${styles.fontSize}`}>Sliding Gate Machine -A180/A/A360</p>
                        <Card>
                            <Card.Img variant="top" src={five} />
                        </Card>
                        </Col>
                    <Col md={12}>
                        <Table responsive className="text-light">
                            <thead>

                                <tr>
                                    <th>Sr.</th>
                                    <th>MODAL SPECIFICATIONS</th>
                                    <th>SL-A-120</th>
                                    <th>SL-A-200</th>
                                    <th>SL-A-180<br />VFD</th>
                                    <th>SL-A-360<br />200 GB-VFD</th>
                                    <th>SL-A-360<br />VFD</th>
                                    <th>SL-A-540<br />VFD</th>
                                    <th>SL-A-750<br />VFD</th>
                                    <th>SL-A-SP<br />VFD</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td>1</td>
                                    <td>OVERALL SIZE(WXDXH)</td>
                                    <td>200X160X280</td>
                                    <td>230X185X290</td>
                                    <td>342X200X300</td>
                                    <td>342X200X300</td>
                                    <td>330X250X450</td>
                                    <td>400X250X500</td>
                                    <td>400X250X500</td>
                                    <td>CUSTOM BUILT</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>SUITABLE FOR GATE WT
                                    </td>
                                    <td>100-200 KG
                                    </td>
                                    <td>100-300KG</td>
                                    <td>100-300KG</td>
                                    <td>200-500KG</td>
                                    <td>500-800KG</td>
                                    <td>800-1200KG</td>
                                    <td>1200-1600KG</td>
                                    <td>ABOOVE 1600KG</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>MAXIMUM DUTY CYCLES PER DAY</td>
                                    <td>10-15 CYCLES</td>
                                    <td>10-15 CYCLES</td>
                                    <td>15-30 CYCLES</td>
                                    <td>15-30 CYCLES</td>
                                    <td>40-50 CYCLES</td>
                                    <td>50-60 CYCLES</td>
                                    <td>80-100 CYCLES</td>
                                    <td>80-100 CYCLES</td>
                                </tr>
                                <tr>
                                    < td>4</td>
                                    <td>DRIVE MECHANISM</td>
                                    < td>RACK PINION</td>
                                    < td>RACK PINION</td>
                                    < td>RACK PINION</td>
                                    < td>RACK PINION</td>
                                    < td>RACK PINION</td>
                                    <td>RACK PINION</td>
                                    < td>RACK PINION</td>
                                    <td>RACK PINION</td>
                                </tr>
                                <tr >
                                    <td>5</td>
                                    <td>MACHINE PULL</td>
                                    <td>5-15 KGF</td>
                                    <td>15-25KGF</td>
                                    <td>15-25KGF</td>
                                    <td>25-35 KGF</td>
                                    <td>35-45KGF</td>
                                    <td>45-65KGF</td>
                                    <td>45-65KGF</td>
                                    <td>ABOVE 65KGF</td>
                                </tr>
                                <tr >
                                    <td >6</td>
                                    <td >MOTOR RATING</td>
                                    <td >125 WATT</td>
                                    <td >200 WATT</td>
                                    <td >180 WATT</td>
                                    <td >360 WATT</td>
                                    <td >360 WATT</td>
                                    <td >540 WATT</td>
                                    <td >760 WATT</td>
                                    <td >AS PER DESIGN</td>
                                </tr>
                                <tr >
                                    <td >7</td>
                                    <td >GEAR BOX-WORM WORM GEAR</td>
                                    <td >GB 120</td>
                                    <td >GB 200</td>
                                    <td >GB 200</td>
                                    <td >GB 360</td>
                                    <td >GB 360</td>
                                    <td >GB 540</td>
                                    <td >GB 760</td>
                                    <td >SP</td>
                                </tr>
                                <tr >
                                    <td >8</td>
                                    <td >MACHINE INPUT POWER SUPPLY</td>
                                    <td >SINGLE PHASE</td>
                                    <td >SINGLE PHASE</td>
                                    <td >SINGLE PHASE</td>
                                    <td >SINGLE PHASE</td>
                                    <td >SINGLE PHASE</td>
                                    <td >SINGLE PHASE</td>
                                    <td >SINGLE PHASE</td>
                                    <td >AS PER DESIGN</td>
                                </tr>
                                <tr >
                                    <td >9</td>
                                    <td >REMOTE OPERATION RANGE </td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                    <td >100 FT</td>
                                </tr>
                                <tr >
                                    <td >1</td>
                                    <td >CONTROL -REMOTE/ELECTRICAL</td>
                                    <td >PR</td>
                                    <td >PR</td>
                                    <td >PR</td>
                                    <td >PR</td>
                                    <td >PR/ELC</td>
                                    <td >PR/ELC</td>
                                    <td >PR/ELC</td>
                                    <td >PR/ELC</td>
                                </tr>
                                <tr >
                                    <td >11</td>
                                    <td >GATE LENGTH - APPROX</td>
                                    <td > 2 MTR</td>
                                    <td >5 MTR</td>
                                    <td >5 MTR</td>
                                    <td >7 MTR</td>
                                    <td >10 MTR</td>
                                    <td >12 MTR</td>
                                    <td >12 MTR</td>
                                    <td >ABOVE 12 MTRS</td>
                                </tr>
                                <tr >
                                    <td >12</td>
                                    <td >GATE OPENING/CLOSING SPEED</td>
                                    <td >13 MTR/MIN</td>
                                    <td >13 MTR/MIN</td>
                                    <td >13 MTR/MIN</td>
                                    <td >15 MTR/MIN</td>
                                    <td >15 MTR/MIN</td>
                                    <td >15 MTR/MIN</td>
                                    <td >15 MTR/MIN</td>
                                    <td >15 MTR/MIN</td>
                                </tr>
                                <tr >
                                    <td >13</td>
                                    <td >OBSTACLE SENSING SYSTEM</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                </tr>
                                <tr >
                                    <td >14</td>
                                    <td >AUDIO / SIGNAL LAMP</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                </tr>
                                <tr >
                                    <td >15</td>
                                    <td >SOFT START AND STOP-VFD</td>
                                    <td >NA</td>
                                    <td >NA</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                    <td >PROVIDED</td>
                                </tr>
                            </tbody>

                        </Table>
                    </Col>
                </Row>

                <Row className="pt-5">
                    <Col md={7} className="m-auto py-5" >
                        <p className={`text-center text-light text-lg-start ${styles.fontSize}`}>Swing Gate Machine -A25</p>
                        <Card>
                            <Card.Img variant="top" src={six} />
                        </Card></Col>
                    <Col md={12} className="text-light">
                        <Table responsive className="text-light text-center">
                            <thead>
                                <tr  >
                                    <td >1</td>
                                    <td >OVERALL SIZE(WXDXH)</td>
                                    <td >90X100X800</td>
                                    <td >90X100X800</td>
                                    <td >110X120X900</td>
                                    <td >110X120X900</td>
                                    <td >CUSTOM BUILT</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  >
                                    <td >1</td>
                                    <td >OVERALL SIZE(WXDXH)</td>
                                    <td >90X100X800</td>
                                    <td >90X100X800</td>
                                    <td >110X120X900</td>
                                    <td >110X120X900</td>
                                    <td >CUSTOM BUILT</td>
                                </tr>
                                <tr  >
                                    <td >2</td>
                                    <td  >SUITABLE FOR GATE WT/LEAF</td>
                                    <td  >100-150KG</td>
                                    <td  >150-350KG</td>
                                    <td  >350-500KG</td>
                                    <td  >500-750KG</td>
                                    <td   >ABOVE 500KG</td>
                                </tr>
                                <tr  >
                                    <td >3</td>
                                    <td  >CYCLES PER DAY</td>
                                    <td >10-15 CYCLES</td>
                                    <td >10-15 CYCLES</td>
                                    <td >20-25 CYCLES</td>
                                    <td >25-40 CYCLES</td>
                                    <td >25-40 CYCLES</td>
                                </tr>
                                <tr  >
                                    <td >4</td>
                                    <td  >MACHINE PULL</td>
                                    <td  >10-15KGF</td>
                                    <td  >15-20KGF</td>
                                    <td  >20-25KGF</td>
                                    <td  >20-25KGF</td>
                                    <td   >ABOVE 25KGF</td>
                                </tr>
                                <tr  >
                                    <td >5</td>
                                    <td  >MOTOR RATING</td>
                                    <td  >70 WATT</td>
                                    <td  >90 WATT</td>
                                    <td  >120 WATT</td>
                                    <td  >180 WATT</td>
                                    <td   >AS PER DESIGN</td>
                                </tr>
                                <tr  >
                                    <td >6</td>
                                    <td  >INPUT SUPPLY</td>
                                    <td  >SINGLE PHASE</td>
                                    <td  >SINGLE PHASE</td>
                                    <td  >SINGLE PHASE</td>
                                    <td  >SINGLE PHASE</td>
                                    <td   >AS PER DESIGN</td>
                                </tr>
                                <tr  >
                                    <td >7</td>
                                    <td  >OPERATED BY</td>
                                    <td  >REMOTE/PB</td>
                                    <td  >REMOTE/P.B.</td>
                                    <td  >REMOTE/P.B.</td>
                                    <td  >REMOTE/P.B.</td>
                                    <td   >REMOTE/P.B.</td>
                                </tr>
                                <tr  >
                                    <td >8</td>
                                    <td  >CONTROLREMOTE/ELECTRICAL</td>
                                    <td  >PR/ELEC</td>
                                    <td  >PR/ELEC</td>
                                    <td  >PR/ELEC</td>
                                    <td  >PR/ELEC</td>
                                    <td   >PR/ELEC</td>
                                </tr>
                                <tr  >
                                    <td >9</td>
                                    <td  >GATE OPENING/CLOSING TIME</td>
                                    <td  >13 SEC</td>
                                    <td  >13 SEC</td>
                                    <td  >13 SEC</td>
                                    <td  >13 SEC</td>
                                    <td   >13 SEC</td>
                                </tr>
                                <tr  >
                                    <td >10</td>
                                    <td  >OBSTACLE SENSING DEVICE</td>
                                    <td  >PROVIDED</td>
                                    <td  >PROVIDED</td>
                                    <td  >PROVIDED</td>
                                    <td  >PROVIDED</td>
                                    <td   >PROVIDED</td>
                                </tr>
                                <tr  >
                                    <td >11</td>
                                    <td  >SIGNAL LAMP</td>
                                    <td  >PROVIDED</td>
                                    <td  >PROVIDED</td>
                                    <td  >PROVIDED</td>
                                    <td  >PROVIDED</td>
                                    <td   >PROVIDED</td>
                                </tr>
                                <tr  >
                                    <td>12</td>
                                    <td>SOFT START AND STOP</td>
                                    <td >NA</td>
                                    <td>PROVIDED</td>
                                    <td>PROVIDED</td>
                                    <td>PROVIDED</td>
                                    <td>PROVIDED</td>
                                </tr>

                            </tbody>
                        </Table>
                    </Col>

                    <Col md={3} className="pt-5"><Card >
                        <Card.Img variant="top" src={lift} />
                        <Card.Body>
                            <Card.Title>
                                <p className={`text-center text-lg-start ${styles.fontSize}`}>Self standing lift   </p>
                            </Card.Title>
                            <Card.Text>
                                The lift is having own structural which is grouted on floor. <br />It is a preferably chain driven and having electrical drive. <br />Soft start and stop provides jerk free movement and matching of floor levels.
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>elegant and fast in operation</ListGroup.Item>
                            <ListGroup.Item> can be fitted in showrooms</ListGroup.Item>
                            <ListGroup.Item> Suitable up to the 300 kgs.</ListGroup.Item>
                        </ListGroup>

                    </Card></Col>
                    <Col md={3} className="pt-5"><Card >
                        <Card.Img variant="top" src={lift2} />
                        <Card.Body>
                            <Card.Title>
                                <p className={`text-center text-lg-start ${styles.fontSize}`}>Hydraulic platforms    </p>
                            </Card.Title>
                            <Card.Text>
                                This uses four link mechanism with hydraulic cylinder.<br /> It is very compact and can lift the material from ground.                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>This can be fitted in vehicle for material loading.</ListGroup.Item>
                            <ListGroup.Item>it can be used as a wheel chair lift in all school bus, blood bank and ambulance.</ListGroup.Item>
                            <ListGroup.Item> It can be operated on vehicle engine PTO. </ListGroup.Item>
                            <ListGroup.Item> Suitable up to the 150 kgs.</ListGroup.Item>
                        </ListGroup>

                    </Card></Col>
                    <Col md={3} className="pt-5"><Card >
                        <Card.Img variant="top" src={lift3} />
                        <Card.Body>
                            <Card.Title>
                                <p className={`text-center text-lg-start ${styles.fontSize}`}>Structural supported lift  </p>
                            </Card.Title>
                            <Card.Text>
                                The lift guides are supported on building structures. <br />These are normally provided with rope and winch mechanism.<br /> Soft start and soft stop controls are provided for easy landing.   </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>mechanisms are recommended for industrial applications, heavy loads, ware houses, </ListGroup.Item>
                            <ListGroup.Item> can be fitted in showrooms</ListGroup.Item>
                            <ListGroup.Item>  Suitable up to 1000 kgs</ListGroup.Item>
                        </ListGroup>

                    </Card></Col>
                    <Col md={3} className="pt-5"><Card >
                        <Card.Img variant="top" src={lift4} />
                        <Card.Body>
                            <Card.Title>
                                <p className={`text-center text-lg-start ${styles.fontSize}`}>Custom built material transfer  </p>
                            </Card.Title>
                            <Card.Text>
                                For very specific material movement we offer appropriate systems.<br /> Soft start and soft stop controls are provided for easy landing.   </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item> here is batch loading of process plant where bulk material is transferred to top level and automatically tilted in hopper from where it is fed to reactor through ducting.
                            </ListGroup.Item>
                            <ListGroup.Item> can be fitted in  industrial applications, heavy loads, ware houses, </ListGroup.Item>
                            <ListGroup.Item>  Suitable up to 1000 kgs</ListGroup.Item>
                        </ListGroup>

                    </Card></Col>
                </Row>
                {/* <Row className="mt-5 mb-5">
                    <Col md={6}>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={seven} />
                            <Card.Body>
                                <Card.Title>Door Gear Box</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={eight} />
                            <Card.Body>
                                <Card.Title>Remote</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
                <Col className="text-center h5">DIMENSIONS ARE SUBJECTED TO CHANGE WITHOUT NOTICE.</Col>
            </Container>
        </div>
    );

}

export default Products;