import { Col, Container, Row, Carousel } from "react-bootstrap";
import BgImg from "../../asset/img3.png"
import Office1 from "../../asset/1543473207446.jpg"
import Office2 from "../../asset/gallery2.jpg"

import styles from "./about.module.css"


function About() {
    return (
        <div className="bg-dark ">
            <div style={{
                backgroundImage: `url(${BgImg})`
            }} className={styles.bgimage}>
                <div className={`${styles.blackBg}`}  >
                    <Container >
                        <div className="pt-md-5 p-2" >
                            <h1 className={`fw-bolder pb-md-5 p-2 text-center ${styles.heading}`}>
                                About Us
                            </h1>


                            <h4 className=" text-light  pb-md-5 p-2">
                                Alfa Auto is established on the strong basis of enriched experience in various fields like Design, Manufacturing, Materials, & Marketing.
                            </h4>
                        </div>
                    </Container>
                </div>

            </div>
            <Container className="pt-sm-5 pt-2 text-light">
                <Row>
                    <Col md={12} className="text-center py-sm-5">
                       
                    </Col>
                    <Col md={6}>
                        <h2>The Company is engaged in following main activities-</h2>
                        <ul className={`${styles.MainAct}`}>
                            <li>Design and development of Special Purpose automatic Machines.</li>
                            <li>Process-Automation.</li>
                            <li>Import substitutes and engineering consultancy.</li>
                        </ul>

                    </Col>
                    <Col md={6}>
                        <h2>
                            OUR SPECIALITIES :
                        </h2>
                        <div className="ps-md5">
                            <ul className={`${styles.MainAct}`}>
                                <li>SLIDING GATES</li>
                                <li>SWING GATES</li>
                                <li>FOLDING GATES</li>
                                <li>GARAGE SHUTTERS</li>
                                <li>BOOM BARRIERS</li>
                            </ul>
                        </div>

                    </Col>
                    <h2 className="pt-sm-4 pt-2">COMPANY PROFILE : </h2>
                    <Col md={6}>
                        <p className="py-md-4">Alfa Auto is situated in Industrial area of Satara MIDC having 10000 Sqft. Factory area, equipped with state of the art Machines & Tools required for quality fabrication.</p>
                        <p className="py-md-4">A fully developed subcontractor's base in the vicinity of factory strengthens our production capacities. Company employs skilled manpower for getting best output.</p>
                        <p className="py-md-4">Experience gathered through extensive travelling in domestic and overseas markets has strengthened our abilities to offer best products. Company has latest Design facilities.</p>
                    </Col>
                    <Col md={6}>

                        <Carousel>
                            {/* <Carousel.Item>
                                <div className="card rounded-5" >
                                    <img src={Office} alr="Office img" className="w-100 rounded-5"
                                    />
                                </div>
                                <Carousel.Caption>
                                    <h3>Office</h3>
                                    <p>Poviding the best quality product to every Client.</p>
                                </Carousel.Caption>
                            </Carousel.Item> */}
                            <Carousel.Item>
                                <div className="card rounded-5" >
                                    <img src={Office1} alr="Office img" className="w-100 rounded-5"
                                    />
                                </div>
                                <Carousel.Caption className={styles.shadow}>
                                    <h3>Automatic gate</h3>
                                    <p>Poviding the best quality product to every Client.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="card rounded-5" >
                                    <img src={Office2} alr="Office img" className="w-100 rounded-5"
                                    />
                                </div>
                                <Carousel.Caption className={styles.shadow}>
                                    <h3>Automatic swinging gate</h3>
                                    <p>Poviding the best quality product to every Client.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>

                    </Col>
                </Row>

            </Container>

            <div>
                <Container className="py-sm-3">
                    <Row className="bg-light py-sm-5 pt-2 rounded-3">
                        <Col md={3} className="text-center p-2 "><div><i className={`fas fa-cogs ${styles.fntAwsm}`}> </i> <span className={`${styles.Bluetxt}`}> 2009</span> </div>
                            <div> Founded In 2009</div>
                        </Col>
                        <Col md={3} className={`text-center p-2 ${styles.Lborder}`}><div><i className={`fas fa-warehouse ${styles.fntAwsm}`}></i> <span className={`${styles.Bluetxt}`}> 1</span></div>
                            <div> Special production house</div>
                        </Col>
                        <Col md={3} className={`text-center p-2 ${styles.Lborder}`}><div><i className={`fas fa-door-open ${styles.fntAwsm}`}></i> <span className={`${styles.Bluetxt}`}> 100+</span> </div>
                            <div> 100+ Gates Automated</div>
                        </Col>
                        <Col md={3} className={`text-center p-2 ${styles.Lborder}`}><div><i className={`fas fa-praying-hands ${styles.fntAwsm}`}></i> <span className={`${styles.Bluetxt}`}> 99%</span> </div>
                            <div> Service Satisfaction 99%</div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );

}

export default About;