import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AdminAbout from "../../components/adminpages/about"
function NoAnimationExample() {
    return (
        <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
        >
            <Tab eventKey="Home" title="Home"></Tab>
            <Tab eventKey="About" title="about">
            <AdminAbout />
            </Tab>
            <Tab eventKey="Services" title="services"></Tab>
            <Tab eventKey="Gallery" title="Gallery"></Tab>
            <Tab eventKey="Products" title="Products"></Tab>
            <Tab eventKey="Contact" title="Contact"></Tab>
        </Tabs>
    );
}

export default NoAnimationExample;