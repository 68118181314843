import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import './header.module.css';
import logo from '../../asset/logo1.png'
import style from "./header.module.css"

function Header() {
    return (
        <Navbar bg="dark" color="light" expand="lg" className='sticky-top'>
            <Container>
                <Navbar.Brand href="http://alfaautomation.co.in/" className='fw-bold text-light'><img src={logo} width="48px" className="pe-3 " />ALFA AUTOMATION</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="mx-auto my-2 my-lg-0"
                        navbarScroll
                    >
                        <Nav.Link as={Link} to="/" className={`text-light fs-5 ${style.heading}`}>Home</Nav.Link>
                        <Nav.Link as={Link} to="/about" className={`text-light fs-5 ${style.heading}`}>About</Nav.Link>
                        <Nav.Link as={Link} to="/services" className={`text-light fs-5 ${style.heading}`}>Services</Nav.Link>
                        <Nav.Link as={Link} to="/gallery" className={`text-light fs-5 ${style.heading}`}>Gallery</Nav.Link>
                        <Nav.Link as={Link} to="/products" className={`text-light fs-5 ${style.heading}`}>Products</Nav.Link>
                        <Nav.Link as={Link} to="/contact" className={`text-light fs-5 ${style.heading}`}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;