import Slider from '../../components/slider';
import one from '../../asset/img1.png';
import two from '../../asset/img10.jpg';
import three from '../../asset/IMG_20191225_154712.jpg';
import four from '../../asset/IMG_20191225_135943.jpg';
import five from '../../asset/11      MODEL            A - 360.jpg';
import About from '../about'
import Products from '../products'
import Contact from '../contact'

function Home() {
    

    return (
        < >
            <Slider images={[two, three, four, five, one]} />

            <About />
            {/* <Services /> */}
            <Products />
            <Contact />
        </>
    )
}

export default Home;
