import Carousel from 'react-bootstrap/Carousel';

function Slider(props) {
    return (
        <Carousel>
            {props.images.map((img, ind) => {
                return (
                    <Carousel.Item key={ind}>
                        <img
                            className="d-block w-100"
                            src={img}
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>

                )
            })}
        </Carousel>
    );
}

export default Slider;