import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

function Footer() {
return (
<>
<Container fluid className='bg-dark text-center p-3 text-white'>
      <Row>
        <Col >
        <span className=' '>
        &copy; {new Date().getFullYear()} Copyright | {' '}Developed By Athang Web Solution
        </span> 
        </Col>
        </Row>
      </Container>  
</>
)

}

export default Footer 