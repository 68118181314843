import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import BgImg from "../../asset/img1.png"
import styles from "./gallery.module.css"
import Card from 'react-bootstrap/Card';
import one from '../../asset/gallery1.jpg';
import two from '../../asset/gallery2.jpg';
import three from '../../asset/gallery3.jpg';
import four from '../../asset/gallery4.jpg';
import five from '../../asset/gallery5.jpg';
import six from '../../asset/11      MODEL            A - 360.jpg';
import seven from '../../asset/IMG_20191225_154712.jpg';


function Gallery(props) {
    const [images, setImages] = useState([one, two, three, four, five, six, seven,  "https://www.youtube.com/embed/ft0AizzD4ic", "https://www.youtube.com/embed/6CUJF-5dUNU"])
    return (
        <div className="bg-dark">
            <div className={`${styles.bgImg}`} style={{
                backgroundImage: `url(${BgImg})`,
            }} >
                <div className={`${styles.blackBg}`}  >
                    <Container >
                        <div className="pt-md-5 p-2" >
                            <h1 className={`fw-bolder text-light pb-md-5 p-2 text-center ${styles.heading}`}>
                                Gallery
                            </h1>
                            <h4 className=" text-light  pb-md-5 p-2">
                             ALFA AUTO is established on the strong basis of enriched experience in various fields like Design, Manufacturing, Materials & Marketing.
                            </h4>
                        </div>
                    </Container>
                </div>
            </div>
            <Container>
                <Row className="pt-5">
                    {images?.map((img, ind) => {
                        return (
                            <Col md={4} key={ind} className="my-3">
                                {img.indexOf('youtube') != -1 ? <iframe className={styles.myCard} width="100%" src={img} height="100%" title="Alfa Sliding Gate" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : <img className="img-fluid" src={img} />}
                            </Col>
                            // <iframe width="1180" height="664" src="https://www.youtube.com/embed/6CUJF-5dUNU" title="Alpha Sliding Gate" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        )
                    })}
                </Row>
            </Container>
        </div>
    );

}

export default Gallery;