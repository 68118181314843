import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AdminAbout() {
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify(inputs));
    }

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col><label>title:
                        <input
                            type="text"
                            name="title"
                            value={inputs.title || ""}
                            onChange={handleChange}
                        />
                    </label></Col>
                    <Col><label>SubTitle:
                        <input
                            type="text"
                            name="SubTitle"
                            value={inputs.SubTitle || ""}
                            onChange={handleChange}
                        />
                    </label></Col>
                    <Col> <label>Specialities:
                        <input
                            type="text"
                            name="Specialities"
                            value={inputs.Specialities || ""}
                            onChange={handleChange}
                        />
                    </label></Col>

                    </Row>

                    <Row>
                        <Col> <label>Number:
                            <input
                                type="text"
                                name="Number"
                                value={inputs.Number || ""}
                                onChange={handleChange}
                            />
                        </label></Col>
                        <Col><label>mail:
                            <input
                                type="email"
                                name="mail"
                                value={inputs.mail || ""}
                                onChange={handleChange}
                            />
                        </label></Col>
                        <Col>
                            <label>main activities:
                                <input
                                    type="text"
                                    name="activities"
                                    value={inputs.activities || ""}
                                    onChange={handleChange}
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>info point 1:
                                <textarea
                                    type="text"
                                    name="infoPt1"
                                    value={inputs.infoPt1 || ""}
                                    onChange={handleChange}
                                />
                            </label> </Col>
                        <Col><label>info point 2:
                            <textarea
                                type="text"
                                name="infoPt2"
                                value={inputs.infoPt2 || ""}
                                onChange={handleChange}
                            />
                        </label></Col>
                        <Col><label>info point 3:
                            <textarea
                                type="text"
                                name="infoPt3"
                                value={inputs.infoPt3 || ""}
                                onChange={handleChange}
                            />
                        </label></Col>
                    </Row>

                    <input type="submit" value="Update" />

            </form>

        </Container>

    )
}

export default AdminAbout;